
.contact-short {
  max-width: 60vw;
  margin: auto;
  align-items: center;
  padding: 5rem 10rem;
  background-color: rgb(187, 3, 3);
  border-radius: 1rem;
  box-shadow: rgb(15, 154, 80);
  transform: translateY(50%);
}

.footer-social--icons {
  display: flex;
  gap: 2rem;
}

.wholefooter{
  /* background-color: #FDFFAE; */
  background: linear-gradient( rgb(249, 249, 249),rgb(235, 235, 174));
}


.icons {
  color: wheat;
  font-size: 2.4rem;
  position: relative;
  cursor: pointer;
}

.footer-bottom--section {
  padding-top: 9rem;

  
}

.hr {
  margin-bottom: 2rem;
  color: black;
  height: 0.1px;
}

.footer1{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

footer {
  padding: 1rem 0 1rem 0;
  background-color: aqua;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footer-subscribe{
  color: red;
      margin-bottom: 2.4rem;
}


.p{
  color: rosybrown;
}

#h3 {
  color: black  ;
  margin-bottom: 0.4rem;
}

.footer-about{
  padding-left: 0px;
  /* background-color: #ededc3; */
  /* outline: #99292D; */
  opacity: 0.9;
  background: linear-gradient(rgb(235, 235, 174), rgb(243, 245, 211));
}

.footer-about1{
  padding-left: 0px;
}

.icons1 {
  color: #3b5998 ;
  font-size: 2.4rem;
  position: relative;
  cursor: pointer;
}

.icons2 {

  color: black;
  font-size: 2.4rem;
  position: relative;
  cursor: pointer;
}

.icons3 {
  

  color:#99292D;
  font-size: 2.4rem;
  position: relative;
  cursor: pointer;
}

#h2{
  font-size: 20px;
}

.footer-bottom--section {
 
  padding-top: 1rem;
  align-items: center;
}

.container-grid-grid-two-column{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.hr {
  
  margin-bottom: 1rem;
  color: #99292D;
  height: 20rem;
}

@media screen and (max-width:769px){
  footer {
    padding: 1rem 0 1rem 0;
    background-color: rgb(27, 168, 168);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .footer1{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}