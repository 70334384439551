.news-ticker {
    overflow: hidden;
    white-space: nowrap;
    background-color: #ffffff; /* Background color */
    color: #c20d0d; /* Text color */
    width: 100%;
  }
  
  .ticker-text {
    display: inline-block;
    padding-left: 100%; /* Start off-screen */
    animation: ticker 15s linear infinite;
  }
  
  @keyframes ticker {
    to {
      transform: translateX(-100%);
    }
  }
  