/* TableComponent.css */
table {
    border-collapse: collapse;
    width: 100%;
  }
  
  table th, table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  table th {
    background-color: #f2f2f2;
  }
  
  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  table tr:hover {
    background-color: #ddd;
  }
  
  /* Add specific styles for the headers and rows */
  table th {
    font-weight: bold;
  }
  
  table th,
  table td {
    padding: 12px;
  }
  
  /* Add styles for the "S.No." column header */
  table th:first-child {
    background-color: #333;
    color: white;
  }
  
  /* Add styles for the "Member Name" column header */
  table th:nth-child(2) {
    background-color: #009688;
    color: white;
  }
  
  /* Add styles for the "Father Name" column header */
  table th:nth-child(3) {
    background-color: #ff5722;
    color: white;
  }
  
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  button {
    background: #2196f3;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  button:hover {
    background: #1976D2;
  }
  
  button > svg {
    margin-right: 8px; /* Add space between icon and text */
  }
  