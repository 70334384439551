 @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,900&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.headert1 {
  font-size: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  letter-spacing: 0.481em;
  color: #99292D;
}

.headert2 {
  font-size: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  letter-spacing: 0.100em;
}

.outer1 {
  width: auto;
  height: auto;
  
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  /* position: fixed; */

}

.headert3 {
  font-size: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  letter-spacing: 0.481em;
}

.main {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  justify-content: space-between;
  align-items: center;
  /* font-family: 'Merriweather', serif; */
  /* font-family: 'Merriweather', serif; */
  font-family: Georgia, serif;
}

.outer2 {
  flex: 1;
  padding: 10px;
  font-size: 25px;
  text-align: justify;
}

.text1 {
  display: flex;
  align-items: flex-end;
}

.ima1 {
  height: 150px;
  width: 150px;
  margin-left: 20px;
}

.main1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  font-family: Georgia, serif;
}

.outer4 {
  width: auto;
  height: auto;
  font-size: 25px;
  padding: 20px;
  text-align: justify;
}

.ima2 {
  height: 400px;
  width: 400px;
  margin-bottom: 10px;
}

.hoverbutton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.outer3 {
  width: auto;
  height: auto;
  justify-content: center;
  padding-top: 20px;
}

.o3h1 {
  font-size: 30px;
  letter-spacing: 0.2em;
  text-align: center;
  margin-bottom: 20px;
}

.secondmain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

.secondmain1,
.secondmain2,
.secondmain3 {
  margin-bottom: 20px;
}


.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 2140px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out;
  height: 720px;
}



.btn-success {
  color: #fff;
  background-color: #0171b9;
  border-color: #0171b9;
}

#three {
  height: 200px;
  width: 200px;
  margin-left: 18%;

}


.youtube {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.youtube iframe{
  width: 1920px;
  height: 480px;
}

.quote {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  text-align: center;
  margin-top: 30px;
  /* background: linear-gradient(#FF671F, #FFFFFF, #046A38); */
}

.yt_text{
  font-size: 130%;
  padding-left: 20px;
}


@media (max-width: 600px) {

  .headert2 {
    font-size: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    letter-spacing: 0.100em;
  }

  .main {
    flex-direction: column;
    padding-top: 20px;
  }

  .outer2 {
    font-size: 20px;
    text-align: justify;
  }

  .ima1 {
    height: 120px;
    width: 120px;
    margin-left: 0;
    margin-top: 10px;
  }

  .outer4 {
    font-size: 20px;
    text-align: justify;
  }

  .ima2 {
    height: 300px;
    width: 300px;
  }

  .hoverbutton {
    margin-top: 10px;
  }

  .o3h1 {
    font-size: 24px;
  }

  .secondmain {
    position: relative;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .secondmain1,
  .secondmain2,
  .secondmain3 {
    width: 85%;
  }

  .youtube {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
  }

  .youtube iframe{
    width: 370px;
    height: 240px;
  }
}