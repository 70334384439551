.card-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  perspective: 1000px;
}

.card_a {
  width: 200px;
  height: 300px;
  margin: 20px;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  cursor: pointer;
  will-change: transform, opacity;
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.s;
  backface-visibility: hidden;
}

.card_a:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1; 
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); 
}



.fullscreen-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 50%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.fullscreen-image {
  max-width: 50%; 
  max-height: 70vh; 
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

