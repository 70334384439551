.task_card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.task_card {
  width: 80%;
  margin: 10px auto;
}

.row{
  display: flex;
  flex-direction: row;
}

.optchose{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#label{
  color: red;
}

.heading{
  display: flex;
  justify-content: center;
  color: rgba(255, 2, 2, 0.979);
  text-shadow: #333;
}

.ststus_user{
  width: 500px;
}

.input-group {
  position: relative;
  width: 320px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-group input {
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: #333;
  padding: 0 10px;
  background: transparent;
  border: 1px solid #333;
  outline: none;
  border-radius: 5px;
}

.input-group textarea {
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: #333;
  padding: 0 10px;
  background: transparent;
  border: 0.5px solid #333;
  outline: none; 
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .optchose{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .heading{
    display: flex;
    justify-content: center;
    color: blue;
  }

  .head{
    text-align: center;
  }

  #label{
    padding-bottom: 5px;
  }
}

.button_hover{
  background: transparent;
  color: black;
  transition: ease-in-out 102ms;
}
.button_hover:hover{
  background: yellow;
  color: black;
}

.delete_button_hover{
  background: transparent ;
 
}

.delete_button_hover:hover{
  background: yellow ;
  opacity: 50%;
}

.update_status_button{
  background: white;
  color: black;
}
.update_status_button:hover{
  background: rgb(201, 9, 9);
  color: rgb(255, 255, 255);
}