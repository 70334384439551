.Container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 50px;
  position: sticky;
}


.logo {
  display: flex;
  font-size: x-large;
  margin-left: -30%;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative; 
}

@import url('https://fonts.googleapis.com/css2?family=Nova+Square&display=swap');
.plogo{
  font-family: 'Nova Square', sans-serif;
}

.bg-body-tertiary {
  padding-top: 0px;
  padding-bottom: 6px;
  background: linear-gradient(rgb(235, 235, 174), rgb(249, 249, 249));
  opacity: 0.99;
 
}
.space {
  display: flex;
  flex-direction: row;
}

.text-end{
  font-size: 15px;
  color: aqua;
}

.he{
  color: red;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 150%;
}



@media screen and (max-width:600px) {
  .logo {
    position: relative;
    display: flex;
    font-size: large;
    margin-left: -3px;
    justify-content: flex-start;
    align-items: flex-start;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  .space {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

@media screen and (max-width:820px) {
  .logo {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    font-size: large;
    margin-left: -35px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .space {
    display: flex;
    flex-direction: row;
    width: 100%;

  }
}