.wrapper {
    background-color: rgba(0, 0, 0, 0.6);
    padding-bottom: 50px;
    padding-top: 50px;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
}

.form-wrapper_sign-in {
    margin: 0 auto;
    width: 550px;
    height: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    /* padding: 100px 70px 100px 70px; */
    padding-top: 50px;
    padding-left: 70px;
    padding-right: 70px;
}

.form-wrapper_sign-in h1 {
    color: white;
    padding-bottom: 10px;
}

.input-text {
    margin-bottom: 20px;
}

.input-text input {
    width: 100%;
    height: 45px;
    background-color: #333;
    color: white;
    border-radius: 5px;
    border: none;
    outline: transparent;
    text-indent: 18px;
}

.input-text input::-webkit-input-placeholder {
    font-size: 1rem;
    color: #999;
}

.input-text input::-moz-placeholder {
    font-size: 1rem;
    color: #999;
    text-indent: 40px;
}

.input-text label {
    color: aliceblue;
}

.signin-button {
    margin-top: 20px;
    width: 100%;
    padding: 13px;
    border-radius: 5px;
    border-color: white;
    background-color: red;
    color: white;
    border: 0.5px solid white;
    font-weight: bold;
    font-size: 1.05rem;
    cursor: pointer;
}

.signin-button:hover {
    margin-top: 20px;
    width: 100%;
    padding: 13px;
    border-radius: 5px;
    border-color: white;
    background-color: red;
    color: white;
    border: 0.5px solid white;
    font-weight: bold;
    font-size: 1.05rem;
    cursor: pointer;
}

.new-members {
    margin-bottom: 10px;
    color: #8c8c8c;
}



.form-wrapper_sign-up {
    margin: 0 auto;
    width: 810px;
    height: 100%;
    
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    padding: 30px 70px 143px 70px;
    

}

.form-wrapper_sign-up h1 {
    color: white;
    padding-bottom: 10px;
}

.input-block1 {
    margin-bottom: 20px;
}

.input-block1 label {
    color: aqua;
}

.input-text1 {
    margin-bottom: 20px;
}

.input-text1 input {
    width: 100%;
    height: 45px;
    background-color: #333;
    color: white;
    border-radius: 5px;
    border: none;
    outline: transparent;
    text-indent: 18px;
}

.input-text1 label {
    color: aqua;
}

.form-wrapper_sign-up p{
    color: red;
}
.signup-button {
    margin-top: 20px;
    width: 100%;
    padding: 13px;
    border-radius: 5px;

    background: linear-gradient(to right, #d81b60, #1e87d5);
    color: white;
    font-weight: bold;
    font-size: 1.05rem;
    cursor: pointer;
    transition: background 10.0s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.signup-button:hover {
    background: linear-gradient(to right, #1e87d5, #d81b60);
}

.new-members1 {
    margin-bottom: 10px;
    color: #8c8c8c;
}



/* Initial hidden state */
.form-wrapper_sign-up.fade-out,
.login.fade-out {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

/* Class for fade-in animation */
.form-wrapper_sign-up.fade-in,
.login.fade-in {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}